var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "components_module_recycle_bin_main_page bbox",
    },
    [
      _c(
        "div",
        {
          ref: "list",
          staticClass: "list_group d-flex align-start justify-start flex-wrap",
        },
        [
          _vm.tagType === 0
            ? [
                _vm._l(_vm.list, function (item, index) {
                  return [
                    _c("thumb-task", {
                      key: index,
                      staticClass: "mr-10 mb-10 cp",
                      attrs: {
                        item: item,
                        recycle: true,
                        show_from: true,
                        show_draft: true,
                      },
                      on: {
                        handleClick: _vm.openTask,
                        handleRecover: _vm.recoverTask,
                        completelyDelete: _vm.completelyDelete,
                      },
                    }),
                  ]
                }),
              ]
            : _vm._e(),
          _vm.tagType === 1
            ? [
                _vm._l(_vm.list, function (item, index) {
                  return [
                    _c("CoursePackage", {
                      key: index,
                      staticClass: "bbox_wrapper",
                      attrs: {
                        "course-package": item,
                        "can-recover": true,
                        "can-delete": false,
                      },
                      on: { handleRecover: _vm.recoverCourse },
                    }),
                  ]
                }),
              ]
            : _vm._e(),
          _vm.nomore
            ? _c(
                "div",
                {
                  staticClass:
                    "nomore mr-10 mb-10 cp placeholder d-flex align-center justify-center",
                },
                [_vm._v("\n      没有更多了\n    ")]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "more nomore mr-10 mb-10 cp placeholder d-flex align-center justify-center",
                },
                [_vm._v("\n      加载更多\n    ")]
              ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass:
                "loading mr-10 mb-10 placeholder d-flex align-center justify-center",
            },
            [_c("loading")],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }