<template>
  <div
    class="components_module_recycle_bin_main_page  bbox"
    ref="wrapper"
  >
    <div class="list_group d-flex align-start justify-start flex-wrap"
      ref="list"
    >

      <template
        v-if="tagType === 0"
      >
        <template
          v-for="(item, index) in list"
        >
          <thumb-task
            :key="index"
            :item="item"
            :recycle="true"
            :show_from="true"
            :show_draft="true"
            class="mr-10 mb-10 cp"
            @handleClick="openTask"
            @handleRecover="recoverTask"
            @completelyDelete="completelyDelete"
          ></thumb-task>
        </template>
      </template>
      <template
        v-if="tagType === 1"
      >
        <template
          v-for="(item, index) in list"
        >
          <CoursePackage
            class="bbox_wrapper"
            :key="index"
            :course-package="item"
            :can-recover="true"
            :can-delete="false"
            @handleRecover="recoverCourse"
          ></CoursePackage>
        </template>
      </template>
      <div
        class="nomore mr-10 mb-10 cp placeholder d-flex align-center justify-center"
        v-if="nomore"
      >
        没有更多了
      </div>
      <div
        v-else
        class="more nomore mr-10 mb-10 cp placeholder d-flex align-center justify-center"
      >
        加载更多
      </div>
      <div class="loading mr-10 mb-10 placeholder  d-flex align-center justify-center"
        v-show="loading"
      >
        <loading></loading>
      </div>
    </div>
  </div>
</template>

<script>
import ThumbTask from '@/components/Common/ThumbTask.vue';
import Loading from '@/components/basic/loadingIcon.vue';
import request from '@/api/request'
import CourcePackageModel from '@/models/cource_package'
import { listConvertToModel } from '@/models'
import CoursePackage from '@/views/fun_template/course_package'
export default {
  components: {
    ThumbTask,
    Loading,
    CoursePackage,
  },
  props: {
    // list: {
    //   type: Array,
    //   default: function(){
    //     return [];
    //   }
    // }
    tagType: {
      type: Number,
      default: 0,
    }
  },
  data(){
    return {
      list: [],
      nomore: false,
      loading: false,
      keyword: '',
      bottom_distance: -1,
      page: undefined,
    }
  },
  computed:{
    recycle_delete_id(){
      return this.$store.state.recycle_delete_id;
    },
    recycle_clear_all(){
      return this.$store.state.recycle_clear_all;
    },

  },
  watch:{
    recycle_delete_id: function(val){
      if(val !== ''){
        this.deleteTask(val);
        this.$store.commit('set_recycle_delete_id', '');
      }
    },
    recycle_clear_all: function(val){
      if(val){
        this.clearAll();
        this.$store.commit('set_recycle_clear_all', false);
      }
    },
    bottom_distance: function(val){
      const { loading, nomore} = this;
      if(loading || nomore){
        return;
      }
      if(val!=-1 && val<100){
        // console.log('loadmore')
        this.loadingMore();
      }
    },
  },
  async mounted(){
    this.init();
  },
  methods: {
    openTask(item){
      this.$api.moduleTask.openTask({
        taskId: item.id,
        config: {
          recycle: true,
        }
      })
    },
    recoverCourse (coursePackage) {
      console.log(coursePackage)
      this.$api.recycle_bin.recover(coursePackage, 'course_package')
    },
    recoverTask(item){
      this.$api.recycle_bin.recover(item);
    },
    completelyDelete(item){
      this.$api.recycle_bin.completeDelete(item);
    },
    async loadingMore(){
      const start = this.list.length;
      const {keyword} = this;
      const res = await this.query({start, keyword});
      if(res.message === 'success'){
        this.$set(this, 'list', this.list.concat(res.contents.taskList));
      }
    },

    async query(params){
      this.loading = true;
      const {keyword,start} = params || {};
      const res = await this.$global.doPost({
        url: '/projectTask/queryDeleteTaskList',
        data: {keyword,start},
      })
      const {contents} = res;
      if(!contents.taskList || contents.taskList.length != 20){
        this.nomore = true;
      }
      this.loading = false;
      return res;
    },
    // 删除任务
    deleteTask(id){
      // const {id} = item;
      const {list} = this;
      const index = list.findIndex(unit=>{
        return unit.id == id;
      })
      if(index != -1){
        list.splice(index, 1);
      }
    },
    clearAll(){
      this.$set(this, 'list', []);
      this.nomore = true;
    },
    async init () {
      this.clear()
      const start = this.list.length;
      const {keyword} = this;
      const res = await this.query({start: start || 0, keyword});
      if(res.message === 'success'){
        this.$set(this, 'list', this.list.concat(res.contents.taskList));
      }
      this.$nextTick(async function(){
        // // console.log(this.list);
        // // console.log(this.$refs.wrapper);
        const {nomore} = this;
        const {clientHeight} = this.$refs.wrapper;
        const {scrollHeight} = this.$refs.list; // 目前list 不滚动 clientHeight === scrollHeight;
        // // console.log(this.$refs.list.clientHeight, this.$refs.list.scrollHeight);
        // // console.log({clientHeight , scrollHeight,nomore})
        if((clientHeight > scrollHeight) && !nomore){
          await this.init();
        }else{
          this.addScrollEventListener();
        }
        // // console.log(this.$refs.wrapper.$el.clientHeight);
        // // console.log(this.$refs.list.$el.scrollHeight);
      })
    },
    addScrollEventListener(){
      this.bottom_distance = -1;
      this.$refs.wrapper.addEventListener('scroll', this.scrollListener, false);
    },
    removeScrollEventListener(){
      this.$refs.wrapper.removeEventListener('scroll', this.scrollListener);
      this.bottom_distance = -1;
    },

    scrollListener(){
      const {scrollHeight, scrollTop, clientHeight} = this.$refs.wrapper;
      const calc_height = clientHeight + scrollTop;
      const bottom_distance = scrollHeight - calc_height;
      this.bottom_distance = bottom_distance;
      // // console.log({scrollHeight, calc_height});
    },

    async handleSearch(keyword){
      if(keyword === '' && this.keyword === ''){
        return;
      }
      this.$set(this, 'list', []);
      this.keyword = keyword;
      this.nomore = false;
      this.loading = false;
      await this.init();
    },
    clear () {
      this.$set(
        this,
        'list',
        []
      )
      this.nomore = false
    },
    async initCourse () {
      this.clear ()
      await this.getCourseList()
    },
    async getCourseList () {
      const res = await request.request(
        '/projectTask/queryDeletPCourceList',
        {
          pn: this.page ? this.page.nextPage : 1,
          keyword: this.keyword.trim(),
        }
      )
      if (res.message === 'success') {
        const list = listConvertToModel({
          list: res.contents.page.result,
          model: CourcePackageModel
        })
        this.list = [
          ...this.list,
          ...list
        ]
        console.log(list)
        if (list && list.length === 20) {
          this.nomore = false
        } else {
          this.nomore = true
        }
      }
    },
    async searchCourse (keyword) {
      if(keyword === '' && this.keyword === ''){
        return;
      }

      this.keyword = keyword;
      await this.initCourse()
    }

  }

}
</script>

<style lang="scss" scoped>
.mr-10{
  margin-right: 10px;
}
.mb-10{
  margin-bottom: 10px;
}
.components_module_recycle_bin_main_page{
  .list_group{
    @include scrollbar;
    width: 100%;
    overflow-y: auto;
    padding: 0 10px;
  }
  .placeholder{
    width: 160px;
    height: 140px;
    font-size: 14px;
    color: #999;
    &.more {
      color: $main_blue;
    }
  }

}
</style>

