<template>
  <div
    class="components_module_recycle_bin_header_bar_page"
  >
    <div
      class="tag_wrapper d-flex align-center justify-start"
    >
      <div
        class="tag_group cp"
        :class="{
          on: tagType === 0
        }"
        @click.stop="changeTagType(0)"
      >
        <div
          class="tag"
        >
          任务
        </div>
      </div>
      <div
        class="tag_group cp"
        :class="{
          on: tagType === 1
        }"
        @click.stop="changeTagType(1)"
      >
        <div
          class="tag"
        >
          课程
        </div>
      </div>
    </div>
    <div
      class="search_wrapper d-flex align-center justify-space-between"
    >
      <div
        class="info_group d-flex align-center un_sel"
        v-if="tagType === 0"
      >
        <div class="hint">任务被删除后将保留在此回收站中，最多可保留30天</div>
        <div class="clear_all cp"
             @click="clearAll();"
        >全部清空</div>
      </div>
      <div class="search_group d-flex align-center">
        <div class="icon"></div>
        <input
          type="text"
          class="search bbox"
          placeholder="搜索"
          v-model="search"
          @keydown.enter="doSearch($event);"
        >
        <div
          class="clear cp"
          v-show="search !== ''"
          @click="doClear()"
        ></div>
      </div>
    </div>


  </div>
</template>
<script>
export default {
  props: {
    tagType: {
      type: Number,
      default: 0,
    }
  },
  data(){
    return {
      search: '',
    }
  },
  watch: {
    search: function(val){
      if(val == ''){
        this.$emit('handleSearch', '');
      }
    }
  },
  methods: {
    doClear(){
      this.search = '';
    },
    doSearch(e){
      const is_chinese = this.$api.handleKeydownChinese(e);
      if(is_chinese){
        return;
      }

      if(this.search.trim() != ''){
        this.$emit('handleSearch', this.search.trim());
      }
    },
    clearAll(){
      this.$api.recycle_bin.clearAll();
    },
    changeTagType (type) {
      if (this.tagType === type) {
        return
      }
      this.$emit('changeTagType', type)

    }
  }
}
</script>
<style lang="scss" scoped>
.components_module_recycle_bin_header_bar_page{
  .search_wrapper {
    .hint{
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      padding-right: 10px;
    }
    .clear_all{
      font-size: 14px;
      color: $main_blue;
    }
    .search_group{
      background-color: #E9E9E9;
      position: relative;
      padding: 0 10px;
      height: 30px;
      border-radius: 4px;

      .icon{
        width: 14px;
        height: 14px;
        background-image: url(~@/assets/images/common/search_icon.png);
      }
      .search{
        @include placeholder(#999);
        border: none;
        outline: none;
        background-color: transparent;
        width: 200px;
        line-height: 29px;
        padding: {
          left:5px;
          right: 30px;
        }
        color: #333;
      }
      .clear{
        width: 14px;
        height: 14px;
        background-image: url(~@/assets/images/common/clear_icon.png);
        position: absolute;
        top:50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
  .tag_wrapper {
    @include bbox;
    @include flex;
    width: 100%;
    height: 44px;
    flex-shrink: 0;
    background-color: #fff;
    margin-bottom: 10px;
    .tag_group {
      @include flex;
      @include bbox;
      @include transition;
      justify-content: center;
      width: 100px;
      text-align: center;
      height: 100%;
      font-size: 16px;

      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      border-bottom: 4px solid transparent;
      &.on{
        border-bottom-color: $main_blue;
      }
    }
    .return{
      @include flex;
      .icon{
        @include background(8px, 13px);
        width: 21px;
        height: 21px;
        background-image: url(~@/assets/images/common/return.png);
      }
      .text{
        font-size: 15px;
        color: #333;
      }
    }
  }
}
</style>
