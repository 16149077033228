<template>
  <div
    class="views_fun_template_recycle_bin_page"
  >
    <recycle-bin></recycle-bin>
  </div>
</template>

<script>
import RecycleBin from '@/components/Module/RecycleBin/Container.vue';
export default {
  components: {
    RecycleBin,
  }
}
</script>

<style lang="scss" scoped>
.views_fun_template_recycle_bin_page{
  height: 100%;
  width: 100%;
}
</style>
