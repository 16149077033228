<template>
  <div
    class="components_module_recycle_bin_container_page d-flex flex-column"
  >
    <header-bar
      class="header_bar bbox"
      :tag-type="tagType"
      @handleSearch="handleSearch"
      @changeTagType="changeTagType"
    ></header-bar>
    <main-page
      :tag-type="tagType"
      class="main bbox"
      ref="main"
    ></main-page>
  </div>
</template>

<script>
import HeaderBar from './HeaderBar.vue';
import MainPage from './Main.vue';
export default {
  components: {
    HeaderBar,
    MainPage,
  },
  data(){
    return {
      tagType: 0,
    }
  },
  methods: {
    handleSearch(keyword){
      if (this.tagType) {
        this.$refs.main.searchCourse(keyword)
      } else {
        this.$refs.main.handleSearch(keyword);
      }
    },
    changeTagType (type) {
      this.tagType = type
      if (type) {
        this.$refs.main.initCourse()
      } else {
        this.$refs.main.init()
      }
    }
  },
  mounted(){
    // this.init();
  },

}
</script>


<style lang="scss" scoped>
.components_module_recycle_bin_container_page{
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .header_bar{
    width: 100%;
    //height: 60px;
    border-bottom: 1px solid #f4f4f4;
    padding: 0 30px;
  }
  .main{
    width: 100%;
    flex: 1;
    overflow: auto;
    padding: 20px;
  }

}
</style>
